import _ from 'lodash';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';

import {Button} from '@partssourceinc/react-ui-core';
import CartSummary from 'components/CartSummary';
import PrintQuote from 'components/PrintQuote'
import PurchasePanel from 'components/PurchasePanel';
import PageMetaData from 'components/PageMetaData';
import * as CartStore from 'stores/Cart';
import * as NetworkStore from 'stores/Network';
import * as UserStore from 'stores/User';
import {getPartCondition, getPurchaseChoice, getFormularyDisplayType} from 'productUtility';

import 'less/cart.less';
import axios from 'axios';

@withRouter
@connect((state) => ({cart: state.cart, network: state.network, user: state.user}), CartStore.actionCreators)
export default class ViewCart extends React.Component {
    static displayName = 'Checkout';
    
    static propTypes = {
        cart: CartStore.StateShape,
        network: NetworkStore.StateShape,
        user: UserStore.StateShape,
        ...CartStore.ActionShape,
        ...ReactRouterPropTypes,
    };

    constructor(props) {
        super(props);

        this.state = {
            loadingCart: true,
            preparingCheckout: false,
            isCreditHold: false,
        }
    }

    componentWillMount() {
        const {saveCart, cart} = this.props;
        saveCart(_.merge(cart, {selectedCartItemId: null}));
    }

    componentDidMount() {
        const {network: {isLoggedIn}, loadUserCart, loadGuestCart, cart, resetCheckout, user: {facilities, facility, settings}} = this.props;

        if (isLoggedIn) {
            loadUserCart().then(() => {
                this.setState({loadingCart: false});
            });
        } else {
            loadGuestCart();
        }
        let chFacility = (facility && parseInt(facility.facilityId) > 0) ? facility.creditHold : settings.creditHold;
        if (cart.uniqueFacilities.length > 0) {
            let fcheck = facilities.filter(f => f.facilityId == cart.uniqueFacilities[0].facilityId)[0];
            chFacility = fcheck.creditHold; 
        }  
        this.setState({isCreditHold: chFacility});
        resetCheckout();
        this.submitAdobeCartViewed();
    }

    submitAdobeCartViewed() {
        const {cart} = this.props;
        window.appEventData = window.appEventData || [];
        setTimeout(() => {
            window.appEventData.push({
                'event': 'Cart Viewed',
                'cart': {
                    'cartID': (cart.items && cart.items.length) ? Math.min(...cart.items.map(item => item.orderId)).toString() : '0',
                    'item': cart.items.map(item => ({
                        'price': {
                            'basePrice': item.listPrice.toFixed(2).toString(),
                            'priceTier': '',
                            'priceType': getPartCondition(item.conditionId),
                            'sellingPrice': item.price.toFixed(2).toString(),
                        },
                        'productInfo': {
                            'brand': item.oemName,
                            'businessUnit': item.modality ? item.modality : '',
                            'category': item.category ? item.category : '',
                            'criticalHardDown': item.urgencyId === 1 ? 'True' : 'False',
                            'facilityID': item.facilityId ? item.facilityId.toString() : '',
                            'formularyName': item.formularyRuleName || '',
                            'formularyDisplayType': getFormularyDisplayType(item.suppressionBehavior, item.formularyId),
                            'name': item.oemItemNumber,
                            'productCategory': item.category ? item.category : '',
                            'productCondition': getPartCondition(item.conditionId),
                            'productID': item.oemItemNumber,
                            'productLine': '',
                            'productPurchaseType': getPurchaseChoice(item.purchaseChoice),
                            'productVariant': Object.keys(item.differentiatingAttributes || []).length !== 0 ? Object.entries(item.differentiatingAttributes).map(([key, value]) => `${key}~${value}`).join('|') : '',
                            'refId': item.lineItemId.toString(),
                            'sku': item.vString ? item.vString : '',
                            'vString': item.vString ? item.vString : '',
                        },
                        'quantity': item.quantity,
                    })),
                },
            });
        }, 300);
    }

    proceedToCheckout() {
        this.setState({preparingCheckout: true});
        let {history, saveCheckout, cart, loadFacilityCheckoutSettings, loadFacilityAddresses, user: {settings, info}, getOrderTotals, getSavedCards} = this.props;
        const checkout = {
            items: cart.cartItems.filter(x => x.cartItemId === cart.selectedCartItemId),
            facility: cart.uniqueFacilities.filter(x => x.cartItemId === cart.selectedCartItemId)[0],
            canCheckout: true,
        };

        let facilityId = checkout.facility.facilityId;

        Promise.all([loadFacilityCheckoutSettings(facilityId), loadFacilityAddresses(facilityId), this.checkForShippingCost(checkout.items)]).then((x) => {
            const facilitySettings = x[0].response.data;
            const companyAddresses = x[1].response.data;
            const preCalculatedShipping = x[2];

            const allContractItems = checkout.items.filter(x => !x.isContractProOption).length === 0;
            const hasServiceItems = checkout.items.some(x => x.isServiceItem);

            if (companyAddresses.shippingAddresses.length === 1) {
                checkout.shippingAddress = companyAddresses.shippingAddresses[0];
            } else if (parseInt(settings.defaultShippingAddressId)) {
                checkout.shippingAddress = companyAddresses.shippingAddresses.filter(x => x.addressId === parseInt(settings.defaultShippingAddressId))[0];
            }

            cart.checkout.shippingMethod.shippingInsurance = !hasServiceItems && settings.transitCoverage && facilitySettings.transitCoverage;
            cart.checkout.shippingLocation.attentionTo = checkout.items[0].requestorName;
            cart.checkout.paymentInformation.poNumber = facilitySettings.blanketPoNumber || '';
            cart.checkout.shippingAddresses = companyAddresses.shippingAddresses;
            cart.checkout.billingAddresses = companyAddresses.billingAddresses;

            if (companyAddresses.billingAddresses.length === 1)
                cart.checkout.billingAddress = companyAddresses.billingAddresses[0];

            if ((facilitySettings.canUsePo && !facilitySettings.siteLvlCC) || facilitySettings.holdForPo || facilitySettings.submitForPo)
                cart.checkout.paymentMethods.push({value: 'PO', text: 'Purchase Order #'});

            if (facilitySettings.holdForPo || facilitySettings.submitForPo) {
                cart.checkout.paymentInformation.paymentMethod = cart.checkout.paymentMethods[0];
            }

            if (facilitySettings.prependShipping)
                cart.checkout.shippingLocation.includePo = true;

            if (facilitySettings.prePaidServiceLevelId)
                cart.checkout.shippingMethod.isGsa = true;

            if (preCalculatedShipping.shipCosts.length > 0) {
                if (!preCalculatedShipping.shipCosts[0].shipOption) {
                    preCalculatedShipping.shipCosts[0].shipOption = {};
                }
                cart.checkout.readOnlyShipping = !preCalculatedShipping.canModifyShipping;
                cart.checkout.shippingAddress = preCalculatedShipping.shipCosts[0].shippingAddress;
                                
                // Use normal workflow for single priority - ship location UPMC orders
                cart.checkout.shipCosts = preCalculatedShipping.shipCosts.length > 1 ? preCalculatedShipping.shipCosts : [];

                // Create Shipments
                cart.checkout.shipments = [];

                preCalculatedShipping.shipCosts.forEach(s => {                    
                    let shipment = {};
                    shipment.shippingMethod = {};
                    shipment.selectedShipOption = {};
                    shipment.shippingLocation = {};
                    shipment.id = s.id;
                    shipment.lineItemIds = s.lineItemIds;
                    shipment.shippingMethod.carrierId = s.shipOption.carrierId;
                    shipment.shippingMethod.selectedPriorityId = s.shipOption.serviceTypeId;
                    shipment.selectedShipOption = s.shipOption;
                    shipment.shipOptions = [s.shipOption];
                    shipment.shippingLocation.attentionTo = s.shippingAddress.attentionTo;
                    shipment.shippingLocation.includePo = false;

                    cart.checkout.shipments.push(shipment);
                });
            }

            if (facilitySettings.canUseCC && settings.canUseCC) {
                if (facilitySettings.siteLvlCC)
                    getSavedCards(info.contactId, facilityId).then(x => {
                        x.response.data.forEach(cc => {
                            cart.checkout.paymentMethods.push({value: cc.paymentToken, text: cc.dropDownDisplay});
                        });

                        // fall back to regular logic because no site lvl credit card is available
                        if (x.response.data.length === 0) {
                            if (facilitySettings.canUsePo || facilitySettings.holdForPo || facilitySettings.submitForPo)
                                cart.checkout.paymentMethods.push({value: 'PO', text: 'Purchase Order #'});

                            cart.checkout.paymentMethods.push({value: 'CC', text: 'ADD NEW CREDIT CARD'});
                        }

                        cart.checkout.paymentInformation.paymentMethod = cart.checkout.paymentMethods[0];
                        saveCheckout(_.merge({}, cart.checkout, checkout));
                        getOrderTotals(allContractItems).then(x => {
                            history.push('/checkout');
                        });
                    });
                else
                    getSavedCards(info.contactId, -1).then(x => {
                        x.response.data.forEach(cc => {
                            cart.checkout.paymentMethods.push({value: cc.paymentToken, text: cc.dropDownDisplay});
                        });

                        cart.checkout.paymentMethods.push({value: 'CC', text: 'ADD NEW CREDIT CARD'});
                        cart.checkout.paymentInformation.paymentMethod = cart.checkout.paymentMethods[0];
                        saveCheckout(_.merge({}, cart.checkout, checkout));
                        getOrderTotals(allContractItems).then(x => {
                            history.push('/checkout');
                        });
                    });
            } else {
                if (cart.checkout.paymentMethods.length === 0) {
                    cart.checkout.paymentMethods.push({value: 'PO', text: 'Purchase Order #'});
                }
                cart.checkout.paymentInformation.paymentMethod = cart.checkout.paymentMethods[0];
                saveCheckout(_.merge({}, cart.checkout, checkout));
                getOrderTotals(allContractItems).then(x => {
                    history.push('/checkout');
                });
            }
        });

        let checkoutItems = {};
        checkoutItems = checkout.items.map(x => {
            return {
                'name': x.partNumber,
                'id': x.oemItemNumber,
                'price': x.price,
                'brand': x.oemName,
                'category': 'n/a',
                'variant': `${getPartCondition(x.conditionId)} -  ${x.purchaseChoice}`,
                'quantity': x.quantity,
            }
        });
        dataLayer.push({
            'event': 'checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 'Proceed to Checkout', 'option': 'Proceed to Checkout'},
                    products: checkoutItems,
                },
            },

        });

    }

    checkForShippingCost(cartItems) {
        return new Promise(resolve => {
            if (!cartItems.some(x => x.shipDocumentId)) {
                resolve({shipCosts: []});
            } else {
                const lineItemIds = cartItems.filter(x => x.shipDocumentId).map(x => x.lineItemId);
                axios.post('/ShipIntegrationService/api/v1/rating/line/shipping', lineItemIds).then(x => {
                    resolve(x.data);
                });
            }
        });
    }

    render() {
        const {preparingCheckout, loadingCart, isCreditHold} = this.state;
        const {cart, cart: {selectedCartItemId, cartItems, savedItems, items}, user: {settings: {hidePricing}, facilities}, history} = this.props;
        const allowPurchase = selectedCartItemId != null;

        const facility = allowPurchase ?
            cart.uniqueFacilities.filter(x => x.cartItemId === cart.selectedCartItemId)[0] : null;

        let selectedItems = cartItems.filter(x => x.cartItemId === cart.selectedCartItemId);
        let checkFacility = facility ? facilities.filter(f => f.facilityId == facility.facilityId)[0] : null;
        let disableCheckout = checkFacility ? checkFacility.creditHold : isCreditHold;

        return (<div id="cart-form">
            <PageMetaData 
                title="Cart" 
                pageType="other" 
                trackAnalytics={true} />
            <div className="cart-summary-body">
                
                <h1>Your Cart</h1>
                {!items.length && !loadingCart ?
                    <div className="empty-cart">
                        <div>Your Cart is Empty</div>
                        <Button secondary={true} onClick={() => history.push('/')}>CONTINUE SHOPPING</Button>
                    </div> :
                    <div>
                        {cartItems.length ? <div className="mobile-only">
                            <div className="cart-right-column" style={{marginBottom: '20px'}}>
                                <PurchasePanel allowPurchase={allowPurchase} onSubmit={::this.proceedToCheckout} items={selectedItems} facility={facility} buttonText="PROCEED TO CHECKOUT" creditHold={disableCheckout} />
                            </div>
                        </div> : null}
                        <div className="cart-left-column">
                            {cartItems.length ? <CartSummary allowEdit={true} /> : null}
                            {savedItems.length ? <CartSummary allowEdit={true} showSavedForLater={true} /> : null}
                        </div>
                        {cartItems.length ? <div className="cart-right-column">
                            <div className="desktop-only">
                                <PurchasePanel allowPurchase={allowPurchase} onSubmit={::this.proceedToCheckout} items={selectedItems} facility={facility} loading={preparingCheckout} buttonText="PROCEED TO CHECKOUT" creditHold={disableCheckout} />
                            </div>
                            {hidePricing ? null : <PrintQuote disable={!allowPurchase} isCart={true} />}
                        </div> : null}
                    </div>}
            </div>

        </div>);
    }
}
